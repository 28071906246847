<template>
  <div class="page">
    <div class="dkzc-box">
      <!-- 左侧主要内容 -->
      <div class="left" v-loading="loading">
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
          <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 分享到移动端app引流部分 -->
        <onshare></onshare>

        <video
          v-if="journalism.info.video"
          class="video"
          :src="journalism.info.video"
          :poster="journalism.info.video_images"
          controls
          muted
          autoplay
          style="width: 100%"
        ></video>

        <el-image
          v-else
          style="width: 100%"
          :src="journalism.info.images"
          fit="cover"
        >
        </el-image>

        <div class="content">
          <span>{{ journalism.info.title }}</span>
          <span class="subTitle">{{ journalism.info.subtitle }} </span>
          <div class="vhtml" v-html="journalism.info.content"></div>
          <span class="contentTime">
            {{ journalism.info.create_time | timeFormat }}
          </span>
          <span class="contentPrompt">
            内容来自于{{ journalism.info.source }}
          </span>
        </div>

        <!-- 点赞收藏图标区 -->
        <div class="icons">
          <div class="favoritesNumber">
            <i
              @click="collect"
              class="iconfont"
              :class="{
                shoucanghover: journalism.info.collect_status,
                shoucang: !journalism.info.collect_status,
              }"
            ></i>
            <span>{{ journalism.info.collect_num }}</span>
          </div>
          <div class="like">
            <i
              @click="give(4)"
              class="iconfont"
              :class="{
                zan1: journalism.info.praise_status,
                iconzan2: !journalism.info.praise_status,
              }"
            ></i>
            <span>{{ journalism.info.praise_num }}</span>
          </div>

          <span class="share is_share_show">分享：</span>
          <!-- 第三方分享 -->
          <sharee class="is_share_show" :config="config"></sharee>
        </div>

        <!-- 评论数 -->
        <div class="numberComments" style="width">
          <span class="number">{{ comment_num }}</span>
          <span>条评论</span>
        </div>

        <!-- 评论组件 -->
        <comment
          width="840"
          :type="3"
          @refresh="getNewsInfo"
          :news_id="journalism.info.id"
          :comments="comment"
          @delete_comment="delete_comment"
          ref="comment"
        >
        </comment>
      </div>

      <!-- 右侧信息区 -->
      <div class="right-group">
        <div
          class="group"
          v-for="(item, index) in newsHotList"
          :key="index"
          @click="navtoInfo(item.id)"
          v-show="index < newsHotListNum"
        >
          <img class="information" :src="item.images" v-if="item.images" />
          <!-- <img class="information" src="@image/xinxi.jpg" v-else /> -->
          <div class="right">
            <span class="title">{{ item.title }}</span>
            <span class="time">{{ item.info_count | ct }}次浏览</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

import comment from "@components/common/comment";
import onshare from "@components/share/onShare";
import sharee from "@components/share/share";
export default {
  components: {
    comment,
    onshare,
    sharee,
  },
  filters: {
    ct(val) {
      return val.toString().length > 4 ? (val / 10000).toFixed(1) + "万" : val;
    },
  },
  data() {
    return {
      alertCheng: false,
      loading: false,
      isShow: true,
      star: false,
      content: "",
      journalism: {
        info: {},
        common: [],
      },
      comment: [], //评论列表
      comment_num: 0,
      news_id: 0,
      toUser: {},
      newsHotList: [],
      isBtn: false,
      config: {
        title: "网医-新闻详情",
        url: window.location.href,
        sites: ["wechat", "qq", "weibo"],
        wechatQrcodeTitle: "微信扫一扫：分享",
        wechatQrcodeHelper:
          "<p>扫一扫</p><p>二维码便可将本文分享至朋友圈。</p>",
      },
    };
  },
  created() {
    this.news_id = this.$route.query.id;
    this.getNewsInfo();
    this.getNewHot();
    window.scrollTo(0, 0);

    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userManagement.userInfo,
    }),
    newsHotListNum() {
      return this.newsHotList.length;
    },
  },

  methods: {
    closeAlert() {
      this.alertCheng = false;
    },

    //收藏
    async collect() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      if (this.isBtn) return;
      this.isBtn = true;
      let res = await this.$findApi.findCollection({
        news_id: this.news_id,
        type: 3,
      });
      this.isBtn = false;
      if (res.code == 200) {
        if (this.journalism.info.collect_status) {
          this.journalism.info.collect_num -= 1;
        } else {
          this.journalism.info.collect_num += 1;
        }
        this.journalism.info.collect_status =
          !this.journalism.info.collect_status;
      }
    },
    // funact(item) {
    //   item.isShow = !item.isShow;
    //   this.$forceUpdate();
    // },
    //评论
    async new_comment() {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      let { news_id, content, toUser } = this;
      if (!content) {
        this.$message.error("评论内容不能为空！");
        return;
      }
      let { code, data } = await this.$newsApi.new_comment({
        news_id,
        content,
        p_id: toUser.id || 0,
        reply_id: toUser.user_id || 0,
      });
      if (code == 200) {
        this.$message({
          message: "评论成功！",
          type: "success",
        });
        await this.getNewsInfo();
        this.content = "";
        this.toUser = {};
      }
    },
    //点赞
    async give(type, item) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }
      if (this.star) return;
      this.star = true;
      let { code, data, message } = await this.$findApi.findCommentLike({
        like_id: type == 4 ? this.news_id : item.id,
        type,
      });
      if (type == 4) {
        //文章点赞
        if (code == 200) {
          // this.journalism.is_click?this.$message({ message: '已取消点赞', type: 'success' });:
          this.journalism.info.praise_num += this.journalism.info.praise_status
            ? -1
            : 1;
          this.journalism.info.praise_status =
            !this.journalism.info.praise_status;
        } else {
          this.$message.error(message);
        }
      } else {
        //评论点赞
        if (code == 200) {
          item.praise.num += item.praise ? -1 : 1;
          item.praise = !item.praise;
        } else {
          this.$message.error(message);
        }
      }
      this.star = false;
    },

    // 删除评论
    async delete_comment(id) {
      let { code } = await this.$newsApi.delNewComment({
        news_id: this.news_id,
        comment_id: id,
      });
      if (code == 200) {
        this.comment = this.comment.filter((item) => {
          item.reply = item.reply.filter((it) => {
            return it.id != id;
          });
          return item.id != id;
        });
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getNewsInfo();
      }
    },
    //
    // async findAddComment() {
    //   let { discover_id, content } = this;
    //   let res = await this.$findApi.findAddComment({
    //     discover_id,
    //     content,
    //   });
    //   if (res.code == 200) {
    //     this.item = res.data;
    //   } else {
    //     this.$message.error(res.message);
    //   }
    // },
    // 获取新闻详情
    async getNewsInfo() {
      let news_id = this.news_id;
      this.loading = true;
      let { code, data } = await this.$newsApi.getNewsInfo({
        news_id,
      });
      this.loading = false;
      if (code == 200) {
        this.journalism = data;
        data.common.forEach((v) => {
          v.isShow = false;
        });

        this.comment = data.common;
        this.comment_num = data.info.comment_num;
        this.$forceUpdate();
      }
    },
    // 获取热点新闻
    async getNewHot() {
      let res = await this.$newsApi.getNewsHot();
      if (res.code === 200) {
        this.newsHotList = res.data;
      }
    },
    //获取新闻详情
    async navtoInfo(id) {
      this.news_id = id;
      this.getNewsInfo();
      this.getNewHot();
    },
  },
};
</script>
<style lang="scss" scoped>
.iconfont {
  font-size: 24px;
}

.page {
  > .dkzc-box {
    display: flex;
    flex-direction: row;
    width: 1200px;
    margin: 0 auto;

    > .left {
      display: flex;
      flex-direction: column;
      margin-right: 16px;
      width: 890px;

      > .title {
        font-size: 18px;
        color: #333333;
        font-weight: 600;
        margin-right: 10px;
      }

      > .content {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        color: #333333;
        font-size: 18px;

        > .subTitle {
          line-height: 1.8;
          color: #8f8f8f;
          font-size: 18px;
          overflow: hidden;
          /*必须结合的属性,当内容溢出元素框时发生的事情*/
          text-overflow: ellipsis;
          /*可以用来多行文本的情况下，用省略号“…”隐藏超出范围的文本 。*/
          display: -webkit-box;
          /*必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 。*/
          -webkit-line-clamp: 5;
          /*用来限制在一个块元素显示的文本的行数。*/
          -webkit-box-orient: vertical;
          margin-top: 20px;
        }

        > .banner {
          height: 390px;
          object-fit: cover;
          margin-top: 10px;
        }

        > .contentTime {
          font-size: 14px;
          color: #8f8f8f;
          margin-top: 10px;
        }

        > .contentPrompt {
          font-size: 16px;
          margin-top: 20px;
        }
      }

      > .icons {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #8f8f8f;
        font-size: 24px;
        margin-top: 33px;

        > .favoritesNumber {
          cursor: pointer;
          margin-right: 40px;

          .shoucanghover {
            color: #e60012;
          }
        }

        > .like {
          cursor: pointer;

          .zan1 {
            color: #e60012;
          }
        }

        > .share {
          font-size: 16px;
          margin-left: auto;
        }

        > .qq {
          margin: 0 20px;
        }
      }

      > .numberComments {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: #333333;
        font-size: 24px;
        margin-top: 30px;
        padding-bottom: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f2;

        > .number {
          font-size: 30px;
          color: #e60012;
          margin-right: 5px;
        }
      }
    }

    > .right-group {
      display: flex;
      flex-direction: column;
      width: 294px;
      height: min-content;
      background: #f2f2f2;
      padding: 15px;
      box-sizing: border-box;
      margin-top: 30px;

      > .group {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px dashed #000;

        // &:nth-child(4) {
        //   border-bottom: none;
        // }

        &:first-child {
          margin-top: 15px;
        }

        > .information {
          width: 100px;
          height: 100px;
          object-fit: cover;
          border-radius: 10px;
          margin-right: 10px;
        }

        > .right {
          display: flex;
          flex-direction: column;
          font-size: 14px;

          > .title {
            color: #000;
            line-height: 20px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            -webkit-box-orient: vertical;
            height: 58px;
            margin-bottom: 10px;
          }

          > .time {
            color: #8f8f8f;
            margin-top: auto;
          }
        }
      }
    }
  }

  // > .float_window {
  //   background: #1cd6b9;
  //   position: fixed;
  //   top: 50%;
  //   right: 0;
  //   transform: translateY(-50%);
  //   z-index: 777777;
  //   color: #fff;
  //   padding: 10px;
  //   font-size: 36px;
  //   border-radius: 10px 0 0 10px;
  //   cursor: pointer;
  // }
  // > p {
  //   line-height: 50px;
  // }
}

//评论
.comment {
  display: flex;
  flex-direction: column;

  > .input {
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 30px;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 30px;
    box-sizing: border-box;

    > img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }

    > .shuru {
      width: 100%;
      margin-left: 10px;
      border: 0;
      background: #f2f2f2;
      height: 150px;
      resize: none;
      padding: 10px;
      box-sizing: border-box;
      font-size: 16px;
    }

    > .input-button {
      cursor: pointer;
      position: absolute;
      width: 56px;
      height: 25px;
      background: #8f8f8f;
      border-radius: 5px;
      color: #ffffff;
      text-align: center;
      font-size: 12px;
      line-height: 23px;
      right: 20px;
      top: 115px;
    }
  }

  > .commentsList {
    display: flex;
    flex-direction: column;

    > .comments {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #f2f2f2;
      padding-bottom: 30px;
      box-sizing: border-box;
      margin-top: 30px;

      > img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }

      > .comments-right {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 10px;

        > .top {
          display: flex;
          flex-direction: row;
          color: #333333;
          font-size: 16px;

          > .time {
            color: #d9d9d9;
            margin-left: 30px;
          }
        }

        > .subTitle {
          font-size: 18px;
          color: #333333;
          margin-top: 10px;
        }

        > .bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #8f8f8f;
          font-size: 16px;
          margin-top: 17px;
          cursor: pointer;

          .bottom-number {
            margin-left: 40px;
          }

          .el-icon-arrow-down {
            margin-left: 11px;
          }

          .el-icon-arrow-up {
            margin-left: 11px;
          }

          .dianzan {
            display: flex;
            align-items: center;
            margin-right: 41px;
            font-size: 16px;
            margin-left: auto;
          }
        }
      }
    }

    > .pull-down {
      display: flex;
      flex-direction: column;
      width: 849px;
      background: #fafafa;
      padding: 20px;
      box-sizing: border-box;
      margin-top: 30px;
      margin-left: auto;

      > .down-comments {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 30px;
        box-sizing: border-box;
        margin-bottom: 30px;

        > img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          border-radius: 50%;
        }

        > .comments-right {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 10px;

          > .top {
            display: flex;
            flex-direction: row;
            color: #333333;
            font-size: 16px;

            > .time {
              color: #d9d9d9;
              margin-left: 30px;
            }

            > .reply {
              color: #8f8f8f;
              margin: 0 20px;
            }
          }

          > .subTitle {
            font-size: 18px;
            color: #333333;
            margin-top: 10px;
          }

          > .bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #8f8f8f;
            font-size: 16px;
            margin-top: 17px;

            > .bottom-number {
              margin-left: 40px;
            }

            > .el-icon-arrow-down {
              margin-left: 11px;
            }

            > .dianzan {
              display: flex;
              align-items: center;
              margin-right: 41px;
              font-size: 16px;
              margin-left: auto;
              cursor: pointer;

              .icon {
                font-size: 26px;
              }
            }
          }
        }
      }
    }
  }
}

.zanhover,
.zan1 {
  color: #e60012;
}

.btImg {
  cursor: pointer;
}

.vhtml {
  padding: 10px 0;
  min-height: auto;
  height: auto;
}
.alertCheng {
  width: 40vw;
  height: 20vh;
  border-radius: 30px;
}
</style>