	
<template>
  <div>
    <share :config="config"></share>
  </div>
</template>

<script>
export default {
  props: ["config"],
  data() {
    return {};
  },
  created() {
    // //  分享判断是不是移动端
    // var isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(
    //   navigator.userAgent
    // );
    // if (isMobile) {
    //   window.location.href = window.location.href.concat("m/");
    // }
    // this.config.url = window.location.origin + window.location.hash;
  },
  methods: {},
};
</script>

<style>
</style>
